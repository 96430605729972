//vue imports
import { createApp } from 'vue'
import App from './App.vue'
import router from './router.js';

//assets
import './assets/css/app.css'
import store from './store/store.js'
store.dispatch('auth/updateAuthState').then(() => {
    if (!store.state.auth.isSignedIn) {
        store.dispatch('auth/signInAnonymously')
        store.dispatch('auth/updateAuthState')
    }
})
//firebase sdk
const app = createApp(App)
app.use(router)
app.use(store)
app.mount('#app')

