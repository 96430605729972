<template>
  <div class="fixed top-1/2 left-1/2 p-4 w-full max-w-2xl max-h-full modal">
    <!-- Modal content -->
    <div class="modal relative bg-white rounded-lg shadow signInModal">
      <!-- Modal header -->
      <div class="flex items-center justify-center p-4 md:p-5 border-b rounded-t">
        <h3 class="xl:text-3xl sm:text-2xl text-lg font-semibold text-gray-900">
          Set a username to continue
        </h3>
      </div>
      <!-- Modal body -->
      <div class="p-4 md:p-5 space-y-4">
        <form class="max-w-sm mx-auto" @submit.prevent="updateUserName" novalidate>
          <label for="website-admin" class="block mb-2 text-sm font-medium text-gray-900">Username</label>
          <div class="flex flex-col">
            <div class="flex flex-row" :class="errorMessage ? 'border-solid border-2 border-red-300 rounded-lg' : ''">
              <span
                class="inline-flex items-center px-3 text-sm text-gray-900 bg-gray-200 border border-e-0 border-gray-300 rounded-s-md">
                <svg class="w-4 h-4 text-gray-500" aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path
                    d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                </svg>
              </span>
              <input v-model="username" @input="validateUsername" type="text"
                class="appearance-none focus:outline-none rounded-none rounded-e-lg bg-gray-50 border text-gray-900 block flex-1 min-w-0 w-full text-sm p-2.5"
                placeholder="username" minlength="3" maxlength="8" required>
            </div>

            <label class="text-sm mt-1 text-red-400" v-if="errorMessage != null">{{ errorMessage }}</label>
          </div>
          <button type="submit"
            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-7 py-2.5 text-center mt-5">Submit</button>
        </form>

      </div>
    </div>
  </div>
</template>
<script setup>
import { defineProps, ref } from 'vue';
import { useStore } from 'vuex';
import { firebaseApp } from '../../firebaseConfig.js'; // Update the path to your firebase.js file
import { getFirestore, collection, getDocs, query, where } from 'firebase/firestore';
const store = useStore();
defineProps({
  message: String, // Define the 'message' prop
});
const username = ref(null)
const errorMessage = ref(null)
const updateUserName = async() => {
  const userNameTaken = await isUsernameTaken(username.value)
  if(userNameTaken){
    errorMessage.value = "Username is taken"
  }else if (!errorMessage.value) {
    store.dispatch("auth/setUserName", username.value)
  }
}
const validateUsername = async () => {
  if (username.value == null) {
    username.value = ''
  }
  if (username.value.length < 3) {
    errorMessage.value = "Username must be atleast 3 letters"
  } else {
    errorMessage.value = null
  }
  username.value = username.value.toLowerCase().replace(/[^a-z]{0,8}/g, "")
}
const isUsernameTaken = async(username) => {
  const db = getFirestore(firebaseApp); 
  const usersCollection = collection(db, 'users');
  const queryUsername = query(usersCollection, where('username', '==', username));
  const usernameSnapshot = await getDocs(queryUsername);
  return !usernameSnapshot.empty
}
</script>
