import { firebaseApp } from '../firebaseConfig.js'; // Update the path to your firebase.js file
import { GoogleAuthProvider, onAuthStateChanged, getAuth, signInAnonymously, signInWithPopup } from 'firebase/auth';
import { getFirestore, doc, getDoc, setDoc, query, updateDoc, getDocs, collection, where } from 'firebase/firestore';

const firebaseAuth = getAuth(firebaseApp)
const db = getFirestore(firebaseApp);// this file should always represent my best work it is a file I will be copying from one project to the next so it needs to be constantly improved

const state = {
    isMobileMenuOpen: false,
    isSignedIn: null,
    user: null,
    username: null,
};

const mutations = {
    SET_MOBILE_MENU(state, isOpen) {
        state.isMobileMenuOpen = isOpen;
    },
    SET_SIGNED_IN(state, isSignedIn) {
        state.isSignedIn = isSignedIn;
    },
    SET_USER(state, user) {
        state.user = user;
    },
    SET_USERNAME(state, username) {
        state.username = username;
    },

};

const actions = {
    async signIn() {
        try {
            const provider = new GoogleAuthProvider()
            const result = await signInWithPopup(firebaseAuth, provider)
            const anonUsersConnectionRequests =  await getDocs(query(collection(db, "connectionRequests"), where('guestId', "==", state.user.uid)))
            anonUsersConnectionRequests.forEach((connectionRequest) => {
                updateDoc(connectionRequest.ref, {
                    guestId: result.user.uid
                })
            })
            // find all the connectionRequests with guest ID as the id of the anonymous user and set it to be result.user.uid in batch
            const credential = GoogleAuthProvider.credentialFromResult(result)
            console.log(credential)
        } catch (e) {
            console.log(e)
        }
    },
    async signInAnonymously() {
        try {
            await signInAnonymously(firebaseAuth);
        } catch (error) {
            console.error(error.message);
        }
    },
    async signOut({ dispatch }) {
        try {
            await firebaseAuth.signOut();
            dispatch('signInAnonymously')
        } catch (error) {
            console.error(error.message);
        }
    },
    async setUserName({ commit }, username) {
        try {
            const hostDocRef = doc(db, 'users', state.user.uid);
            await setDoc(hostDocRef, {
                username: username
            })
            commit('SET_USERNAME', username)
        } catch (error) {
            console.error(error.message);
        }
    },
    async updateAuthState({ commit }) {
        return new Promise((resolve) => {
            onAuthStateChanged(firebaseAuth, async (firebaseUser) => {
                //set to signed in if there is a user and its not anonymous
                const isSignedIn = firebaseUser && !firebaseUser.isAnonymous;
                if (firebaseUser) {
                    const hostDocRef = doc(db, 'users', firebaseUser.uid);
                    const docSnapshot = await getDoc(hostDocRef)
                    const userData = docSnapshot.data();
                    commit('SET_USERNAME', userData?.username);
                } else {
                    commit('SET_USERNAME', null);
                }
                commit('SET_SIGNED_IN', isSignedIn);
                commit('SET_USER', firebaseUser);
                resolve(true)
            });
        });
    }
};


export default {
    namespaced: true,
    state,
    mutations,
    actions,
};