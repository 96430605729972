<template>
    <image-and-buttons-modal-template :buttons="buttons" :message="message" :photo-url="photoUrl" />
</template>
<script setup>
import ImageAndButtonsModalTemplate from '../ImageAndButtonsModalTemplate.vue';
import { computed } from 'vue';
const message = computed(() => {
    return "Please close other applications currently using your camera to continue"
})
const photoUrl = computed(() => {
    return 'close-apps-desktop.gif'
})
const buttons = [
]
</script>

<style scoped></style>
