<template>
    <image-and-buttons-modal-template :buttons="buttons" :message="message" :photo-url="photoUrl" />
</template>
<script setup>
import ImageAndButtonsModalTemplate from '../ImageAndButtonsModalTemplate.vue';
import { useStore } from 'vuex';
import { computed } from 'vue';
const store = useStore();
const message = computed(() => {
    if (store.state.browserState.isMobile) {
        return 'The camera on your device is not functioning right. Please try another device'
    } else {
        return 'Please Connect your camera to continue.'

    }
})
const photoUrl = computed(() => {
    if (store.state.browserState.isMobile) {
        return "/use-computer.gif"
    } else {
        return '/connect-camera.gif'
    }
})
const buttons = [
]
</script>

<style scoped></style>
