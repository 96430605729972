<template>
  <nav
    class="lg:px-7 md:px-5 px-2 sm:pt-4 sm:pb-3 py-2 fixed top-0 left-0 right-0 z-[60] flex lg:flex-row flex-col justify-between items-center bg-white w-full">
    <div class="flex flex-row w-full lg:w-auto justify-between">
      <a href="#" class="text-black text-xl font-bold flex flex-row items-center">
        <img src="/logo.png" class="h-[2.2rem] lg:h-[2.2rem] pr-3" />
        <div class="lg:text-2xl text-xl font-light">LiveStation</div>
      </a>

      <button @click="openMobileMenu" v-if="!isMobileMenuOpen"
        class="text-black focus:outline-none lg:hidden flex items-center mr-2">
        <span class="material-symbols-outlined text-5xl">menu</span>
      </button>
      <button @click="closeMobileMenu" v-if="isMobileMenuOpen"
        class="text-black focus:outline-none lg:hidden flex items-center mr-2">
        <span class="material-symbols-outlined text-5xl">
          close
        </span>
      </button>
    </div>
    <!-- Desktop Menu -->
    <div class="hidden lg:flex justify-right space-x-2 text-base xl:text-lg w-[32.5rem] justify-end">
      <router-link :to="{ name: 'home' }"
        class="text-gray-500 font-semibold py-2 px-3 rounded-lg">Home</router-link>
      <router-link :to="{ name: 'host-connection-dashboard' }"
        class="text-gray-500 font-semibold py-2 px-3">Start Accepting Calls</router-link>
      <button v-if="isSignedIn"
        class="text-gray-500 font-semibold py-2 px-3 flex items-center"
        @click="signOut">Log Out</button>
    </div>

    <!-- Mobile Menu Dropdown -->
    <transition name="mobile-menu-fade">
      <div v-if="isMobileMenuOpen" class="lg:hidden pt-1 w-full text-lg mobile-menu-fade">
        <div class="text-black text-center ">
          <router-link @click="closeMobileMenu" :to="{ name: 'home' }" class="block text-black py-2">Home</router-link>
          <router-link @click="closeMobileMenu" :to="{ name: 'host-connection-dashboard' }" class="block text-black py-1">Start
            Accepting Calls</router-link>
          <button v-if="isSignedIn" @click="signOut" class="block text-black w-full py-1">Sign Out</button>
          <button v-else @click="signIn" class="block text-black w-full py-1">Sign In</button>
        </div>
      </div>
    </transition>
  </nav>
  <div v-if="isMobileMenuOpen" class="z-[59] w-screen h-screen fixed top-0 left-0" @click="closeMobileMenu"></div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import router from '../router.js';
import { useRoute } from 'vue-router';
const route = useRoute();
const store = useStore();

const isSignedIn = computed(() => store.state.auth.isSignedIn);
const isMobileMenuOpen = computed(() => store.state.auth.isMobileMenuOpen);


const openMobileMenu = () => {
  store.commit('auth/SET_MOBILE_MENU', true);
}
const closeMobileMenu = () => {
  store.commit('auth/SET_MOBILE_MENU', false);
}

const signIn = async () => {
  closeMobileMenu()
  await store.dispatch('auth/signIn');
  await store.dispatch('auth/updateAuthState')
}

const signOut = async () => {
  closeMobileMenu()
  await store.dispatch('auth/signOut');
  await store.dispatch('auth/updateAuthState')
  if (route.meta.requiresAuth) {
    router.push({ name: 'home' });
  }
}
</script>

<style scoped>
.sign-in-button {
  transition: background-image 1s ease-in-out, opacity 0.5s ease-in-out;
}

.sign-in-button:hover {
  opacity: 1;
  background-image: url(https://assets-global.website-files.com/62c48d78ef34931f8a604ef5/62c4904a072d7e734d9f4a1b_gradient.png);
}

.sign-in-button img {
  pointer-events: none;
}

.mobile-menu-fade {
  opacity: 1;
}

.mobile-menu-fade-enter-active,
.mobile-menu-fade-leave-active {
  transition: opacity 0.2s;
}

.mobile-menu-fade-enter-from {
  opacity: 0;
}

.mobile-menu-fade-leave-to {
  opacity: 0;
}
</style>