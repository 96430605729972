<template>
    <div class="flex flex-row justify-center fixed w-full h-full top-0 bg-black">
        <div class="h-[calc(100%) - 3em] mt-[3rem] w-full">
            <div :class="hostVideoClassList" v-show="callStage == CallProgress.InCall">
                <video webkit-playsinline playsinline ref="hostVideoPlayer" class="h-full w-full object-cover" autohide=1>
                </video>
            </div>
            <div :class="guestVideoClassList" v-show="cameraEnabledState">
                <video webkit-playsinline playsinline muted ref="callerVideoPlayer" class="h-full w-full object-cover"
                    autohide=1>
                </video>
            </div>
            <div :class="guestVideoClassList" class="flex justify-center items-center" v-show="!cameraEnabledState">
                <span class="material-symbols-outlined text-white z-[55] text-[2rem] p-4 w-fit">
                    videocam_off
                </span>
            </div>
            <div class="flex flex-row justify-center w-full absolute top-[7rem]">
                <div class="flex flex-col items-center text-white">
                    <div v-if="callStage == CallProgress.BeforeCall || callStage == CallProgress.InCall || callStage == CallProgress.CallDone"
                        class="w-fit text-center text-6xl font-[100] heading-font">
                        {{ capitalizedHostName }}
                    </div>
                    <div v-if="callStage == CallProgress.InCall"
                        class="flex flex-col w-full justify-center items-center text-lg mt-1">
                        <span>Call ends in {{ formattedTime }}</span>
                    </div>
                    <div class="mt-2" v-if="callStage == CallProgress.BeforeCall">
                        Press the call button to call {{ capitalizedHostName }}
                    </div>
                    <div v-if="callStage == CallProgress.WaitingForCall"
                        class="w-fit text-center text-5xl heading-font font-[100]">
                        Calling {{ capitalizedHostName }}
                        <div v-if="peopleInFrontOfGuest <= 1"
                            class="flex flex-col w-full justify-center items-center text-lg mt-1">
                            <div>Get Ready! You are the next call</div>
                        </div>
                        <div v-else class="flex flex-col w-full justify-center items-center text-lg mt-1">
                            <div>{{ peopleInFrontOfGuest }} Calls are ahead of you</div>
                        </div>
                    </div>
                    <div v-if="callStage == CallProgress.CallDone"
                        class="w-fit text-center text-xl font-[100] heading-font mt-1">
                        Call Ended
                    </div>
                </div>
            </div>
        </div>
        <div class="absolute bottom-[3rem] flex flex-row justify-evenly items-center w-full mx-5 max-w-[40rem]">
            <button v-if="callStage == CallProgress.BeforeCall" @click="makeCall" class="text-center">
                <span class="material-symbols-outlined text-white z-[55] text-[2rem] p-4 rounded-[50%] bg-green-600 w-[64px]">
                    call
                </span>
                <div class="w-full text-center text-white text-lg font-[400] mt-1">Call</div>
            </button>
            <button v-if="callStage == CallProgress.WaitingForCall" @click="skipLine" class="text-center">
                <span class="material-symbols-outlined text-white z-[55] text-[2rem] p-4 rounded-[50%] bg-indigo-500 w-[64px]">
                    fast_forward
                </span>
                <div class="w-full text-center text-white text-lg font-[400] mt-1">
                    Skip the line
                </div>
            </button>
            <button v-if="callStage == CallProgress.InCall" @click="endCall" class="text-center">
                <span class="material-symbols-outlined text-white z-[55] text-[2rem] p-4 rounded-[50%] bg-red-600 w-[64px]">
                    call_end
                </span>
                <div class="w-full text-center text-white text-lg font-[400] mt-1">
                    End Call
                </div>
            </button>
            <button v-if="callStage == CallProgress.CallDone" @click="makeCall" class="text-center">
                <span class="material-symbols-outlined text-white z-[55] text-[2rem] p-4 rounded-[50%] bg-green-600 w-[64px]">
                    call
                </span>
                <div class="w-full text-center text-white text-lg font-[400] mt-1">
                    Call Again
                </div>
            </button>
            <button @click="toggleCameraEnabledState" v-if="cameraEnabledState" class="text-center">
                <span class="material-symbols-outlined text-white z-[55] text-[2rem] p-4 rounded-[50%] bg-green-600 w-[64px]">
                    videocam
                </span>
                <div class="text-white text-lg font-[400] w-[5rem] text-center mt-1">
                    Camera
                </div>
            </button>
            <button @click="toggleCameraEnabledState" v-if="!cameraEnabledState" class="text-center">
                <span class="material-symbols-outlined text-white z-[55] text-[2rem] p-4 rounded-[50%] bg-gray-400 w-[64px]">
                    videocam_off
                </span>
                <div class="text-white text-lg font-[400] w-[5rem] text-center mt-1">
                    Camera
                </div>
            </button>
            <button v-if="callStage == CallProgress.CallDone || callStage == CallProgress.InCall" class="text-center">
                <span class="material-symbols-outlined text-white z-[55] text-[2rem] p-4 rounded-[50%] bg-green-600 w-[64px]">
                    favorite
                </span>
                <div class="w-full text-center text-white text-lg font-[400] mt-1">
                    Tip Creator
                </div>
            </button>
        </div>
    </div>
</template>

  
<script setup>
import { ref, onBeforeUnmount, computed, watch, onMounted, reactive } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router'
import { firebaseApp } from '../firebaseConfig.js'; // Update the path to your firebase.js file
import { getFirestore, onSnapshot, collection, query, where, getDocs, serverTimestamp, updateDoc, addDoc, getDoc } from 'firebase/firestore';
//#== variables
const store = useStore();
const route = useRoute()
const capitalizedHostName = computed(() => {
    const hostName = route.params.hostName
    const firstLetter = hostName.charAt(0).toUpperCase();
    const ending = hostName.slice(1);
    return firstLetter + ending
})
const db = getFirestore(firebaseApp);
const callDocRef = ref(null)
const callerVideoPlayer = ref(null)
const hostVideoPlayer = ref(null)
const callDocUnsubscribeFunction = ref(null)
const iceCandidateUnsubscribeFunction = ref(null)
const unsubscribeFromPeopleInFrontListener = ref(null)
const isDisconnectPending = ref(false)
const peopleInFrontOfGuest = ref(null)
const timeLeftInCall = ref(0)
const timeLeftIntervalId = ref(null)
const cameraEnabledState = ref(true)
const screenSize = reactive({
    width: 0,
    height: 0,
});
class CallProgress {
    static BeforeCall = 'beforeCall';
    static PlacingCall = 'PlacingCall'
    static WaitingForCall = 'WaitingForCall';
    static InCall = 'InCall';
    static CallDone = 'CallDone'
}
const callStage = ref(CallProgress.BeforeCall)
const pageOrientation = computed(() => {
    if (screenSize.width > screenSize.height) {
        return 'landscape'
    } else {
        return 'portrait'
    }
})
const formattedTime = computed(() => {
    const seconds = timeLeftInCall.value;
    if (isNaN(seconds) || seconds < 0) {
        return "Invalid input";
    }

    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
});
const hostVideoClassList = computed(() => {
    const classList = []
    if (pageOrientation.value === 'landscape') {
        classList.push('landscape')
    } else {
        classList.push('portrait')
    }
    if (callStage.value == CallProgress.InCall) {
        classList.push('call-active')
    } else {
        classList.push('not-in-call')
    }
    classList.push('host-video')
    return classList
})
const guestVideoClassList = computed(() => {
    const classList = []
    if (pageOrientation.value === 'landscape') {
        classList.push('landscape')
    } else {
        classList.push('portrait')
    }
    if (callStage.value == CallProgress.InCall) {
        classList.push('call-active')
    } else {
        classList.push('not-in-call')
    }
    classList.push('guest-video')
    return classList
})
const servers = {
    iceServers: [
        {
            urls: ['stun:stun1.l.google.com:19302', 'stun:stun2.l.google.com:19302'],
        },
    ],
    iceCandidatePoolSize: 10,
};
const peerConnection = ref(null)
// events + hooks 
const userMedia = computed(() => {
    return store.state.userMediaState.userMedia
})

//when the browser changes pages it needs to requestUserMedia again to access it
onMounted(() => {
    if (store.state.userMediaState.userMedia) {
        store.dispatch('userMediaState/requestUserMedia')
    }
})

watch(userMedia, (userMedia) => {
    if (userMedia) {
        callerVideoPlayer.value.srcObject = userMedia
        callerVideoPlayer.value.play()
    }
})
onBeforeUnmount(() => {
    if (callerVideoPlayer.value.srcObject) {
        callerVideoPlayer.value.srcObject.getTracks().forEach(track => {
            track.stop();
        });
    }
});
onMounted(() => {
    updateScreenSizeProperty()
    window.addEventListener('resize', updateScreenSizeProperty);
    window.addEventListener('orientationchange', updateScreenSizeProperty)
});

// Remove event listener on component unmount
onBeforeUnmount(() => {
    window.removeEventListener('resize', updateScreenSizeProperty);
    window.removeEventListener('orientationchange', updateScreenSizeProperty)
});
// functions
const getHostUserId = async (username) => {
    const db = getFirestore(firebaseApp);
    const usersCollection = collection(db, 'users');
    const queryUsername = query(usersCollection, where('username', '==', username));
    const usernameSnapshot = await getDocs(queryUsername)
    const firstDocument = usernameSnapshot.docs[0]; // Get the first document
    return firstDocument.id; // Return the UID of the first document
}
const updateScreenSizeProperty = () => {
    screenSize.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    screenSize.height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
};
const toggleCameraEnabledState = async () => {
    cameraEnabledState.value = !cameraEnabledState.value
    if (callDocRef.value) {
        let callDocSnapshot = await getDoc(callDocRef.value)
        const callDocExists = await callDocSnapshot.exists()
        if (callDocExists) {
            await updateDoc(callDocRef.value, {
                guestCameraEnabled: cameraEnabledState.value
            });
        }
    }

}
const disconnectFromHost = async () => {
    if (isDisconnectPending.value == true || callStage.value == CallProgress.PlacingCall) {
        return;
    }
    clearInterval(timeLeftIntervalId.value)
    isDisconnectPending.value = true
    callDocUnsubscribeFunction.value && callDocUnsubscribeFunction.value()
    iceCandidateUnsubscribeFunction.value && iceCandidateUnsubscribeFunction.value()
    unsubscribeFromPeopleInFrontListener.value && unsubscribeFromPeopleInFrontListener.value()
    if (peerConnection.value) {
        peerConnection.value.onicecandidate = null
        peerConnection.value.oniceconnectionstatechange = null
        peerConnection.value.ontrack = null
        peerConnection.value && peerConnection.value.close()
        peerConnection.value = null
    }
    const callDocSnapshot = await getDoc(callDocRef.value)
    const callDocExists = await callDocSnapshot.exists()
    if (callDocExists) {
        await updateDoc(callDocRef.value, {
            ended: true
        });
    }
    console.log('finsihed disconnecting')
    isDisconnectPending.value = false
    callDocRef.value = null
    return;
}
const makeCall = async () => {
    timeLeftInCall.value = null
    clearInterval(timeLeftIntervalId.value)
    if (callStage.value === CallProgress.PlacingCall || callStage.value === CallProgress.InCall || isDisconnectPending.value == true) {
        return
    }
    callStage.value = CallProgress.PlacingCall
    console.log('starting to place call')
    const hostId = await getHostUserId(route.params.hostName)
    const connectionRequestsCollection = collection(db, 'connectionRequests')
    callDocRef.value = await addDoc(connectionRequestsCollection, {
        hostOffer: null,
        guestAnswer: null,
        hostId: hostId,
        guestId: store.state.auth.user.uid,
        guestCameraEnabled: cameraEnabledState.value,
        timestamp: serverTimestamp() // Add a timestamp field with the server time
    });
    const callDocSnapshot = await getDoc(callDocRef.value)
    const callDocTimeStamp = callDocSnapshot.data().timestamp
    const milliseconds = callDocTimeStamp.seconds * 1000 + callDocTimeStamp.nanoseconds / 1000000;
    const timeStampDate = new Date(milliseconds)
    unsubscribeFromPeopleInFrontListener.value = onSnapshot(query(connectionRequestsCollection, where('timestamp', '<', timeStampDate), where('hostId', '==', hostId)), (snapshot) => {
        peopleInFrontOfGuest.value = snapshot.size;
        console.log('calls')
    });

    callDocUnsubscribeFunction.value = onSnapshot(callDocRef.value, async (document) => {
        let documentData = document.data();
        if (documentData?.hostOffer && !documentData?.guestAnswer) {
            peerConnection.value = new RTCPeerConnection(servers)
            store.state.userMediaState.userMedia.getTracks().forEach((track) => {
                peerConnection.value.addTrack(track, store.state.userMediaState.userMedia);
            });
            const remoteStream = new MediaStream();
            peerConnection.value.ontrack = (event) => {
                event.streams[0].getTracks().forEach((track) => {
                    remoteStream.addTrack(track);
                });
            };
            hostVideoPlayer.value.srcObject = remoteStream
            hostVideoPlayer.value.play()
            peerConnection.value.oniceconnectionstatechange = () => {
                const iceConnectionState = peerConnection.value.iceConnectionState;
                timeLeftInCall.value = 120
                if (iceConnectionState === "connected") {
                    callStage.value = CallProgress.InCall
                    timeLeftIntervalId.value = setInterval(() => {
                        timeLeftInCall.value -= 1
                    }, 1000)
                }
            };
            peerConnection.value.onicecandidate = (event) => {
                event.candidate && addDoc(collection(callDocRef.value, 'guestIceCandidates'), event.candidate.toJSON());
            };
            await peerConnection.value.setRemoteDescription(new RTCSessionDescription(JSON.parse(documentData.hostOffer)));
            const answerDescription = await peerConnection.value.createAnswer();
            await peerConnection.value.setLocalDescription(answerDescription);
            await updateDoc(callDocRef.value, {
                guestAnswer: JSON.stringify(answerDescription)
            });
            const iceCandidatesRef = collection(callDocRef.value, 'hostIceCandidates');
            iceCandidateUnsubscribeFunction.value = onSnapshot(iceCandidatesRef, (snapshot) => {
                iceCandidateUnsubscribeFunction.value()
                snapshot.docChanges().forEach((change) => {
                    if (change.type === "added") {
                        const iceCandidateData = change.doc.data();
                        peerConnection.value.addIceCandidate(new RTCIceCandidate(iceCandidateData));
                    }
                });
            });
            window.onbeforeunload = () => {
                disconnectFromHost()
            }
        } else if (!document.exists()) {
            await disconnectFromHost()
            callStage.value = CallProgress.CallDone
        }
    })
    console.log('finished placing call')
    callStage.value = CallProgress.WaitingForCall
    //Call in logic
}
const skipLine = async () => {
    return;
}
const endCall = async () => {
    await disconnectFromHost()
    callStage.value = CallProgress.CallDone
}
</script>
  
<style scoped>
.guest-video video,
.host-video video {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.guest-video.portrait.call-active {
    min-width: 100%;
    height: 50%;
    right: 0;
    bottom: 0;
}

.host-video.portrait {
    min-width: 100%;
    height: 50%;
    right: 0;
    top: 0;
}

.guest-video.landscape.call-active {
    position: absolute;
    height: 100vh;
    width: 50%;
    left: 0;
    bottom: 0;
}

.host-video.landscape {
    position: absolute;
    height: 100vh;
    width: 50%;
    right: 0;
    bottom: 0;
}

.guest-video.not-in-call {
    min-width: 100%;
    height: 100%
}

/* Hide the default play button on Chrome */
video::-webkit-media-controls-enclosure {
    display: none !important;
}

/* Hide other controls you may not need (optional) */
video::-webkit-media-controls {
    display: none !important;
}
</style>