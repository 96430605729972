import { createRouter, createWebHistory } from 'vue-router';
import HomePage from './views/HomePage.vue'
import HostConnectionDashboard from './views/HostConnectionDashboard.vue'
import CallInDashBoard from './views/CallInDashBoard.vue'
import { useStore } from 'vuex';
const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            component: HomePage,
            name: 'home',
            meta: {
            }
        },
        {
            path: '/host-connection-dashboard',
            component: HostConnectionDashboard,
            name: 'host-connection-dashboard',
            meta: {
                requiresAuth: true,
                requiresUserName: true,
                requiresUserMedia: true
            }
        },
        {
            path: '/@:hostName',
            component: CallInDashBoard,
            name: 'call-in-dashboard',
            meta: {
                requiresUserMedia: true
            }
        }
    ]
});

router.beforeEach(() => {
    const store = useStore();
    store.commit('auth/SET_MOBILE_MENU', false);
})

export default router;