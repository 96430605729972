<template>
    <div class="text-center flex flex-col justify-center items-center">
        <h1 class="text-3xl md:text-4xl lg:text-5xl font-extralight mb-3">Call-in Dashboard</h1>

        <div class="flex flex-row items-center mx-4 justify-center">
            <div class="p-2 rounded-lg sm:text-2xl border-solid border-gray-200 border-2">
                {{ callInLink }}
            </div>

            <button
                class="flex flex-row items-center sm:text-xl justify-center px-2 sm:px-5 py-2 bg-indigo-500 hover:bg-indigo-400 text-white rounded-lg ml-1 sm:ml-2"
                @click="copyCallInLink" v-if="displayCopyButton">
                Copy
                <span class="material-symbols-outlined sm:text-2xl ml-1">content_copy</span>
            </button>

            <div class="flex flex-row items-center sm:text-xl justify-center px-2 sm:px-5 py-2 bg-green-400 text-white rounded-lg ml-1 sm:ml-2"
                v-if="!displayCopyButton">
                Copied
                <span class="material-symbols-outlined sm:text-2xl ml-1">done</span>
            </div>
        </div>
        <div
            class="text-gray-600 text-[0.8rem] sm:text-[1.2rem] max-w-full w-[22rem] sm:w-[35rem] md:w-[29rem] lg:w-[34rem] xl:w-[35rem] mt-2 mb-2">
            This is the link viewers use to call into your stream. Place it somewhere on your profile that viewers can
            easily access
        </div>
    </div>
    <div class="flex flex-row justify-center" ref="videoArea" @mousemove="mouseActiveTimer">
        <div :class="fitScreen ? 'fixed w-full h-full top-0 z-[61]' : 'video-area'">
            <div :class="hostVideoClassList" class="aspect-w-16 aspect-h-9 w-full overflow-hidden max-h-full">
                <video muted playsinline webkit-playsinline ref="hostVideoPlayer" class="h-full w-full object-cover"
                    autohide=1>
                </video>
            </div>
            <div class="" v-show="connectionStatus == 'connected'" :class="guestVideoClassList">
                <video webkit-playsinline playsinline class="h-full w-full object-cover" ref="guestVideoPlayer"
                    autohide=1>
                </video>
            </div>
            <div v-show="connectionStatus == 'connected' && !guestCameraEnabled" :class="guestVideoClassList" class="flex justify-center items-center bg-slate-900">
                <span class="material-symbols-outlined text-white z-[55] text-[2rem] p-4 w-fit">
                    videocam_off
                </span>
            </div>
            <div v-if="hostIsLive && !fitScreen"
                class="flex flex-col lg:ml-[2.5rem] lg:bottom-[2.5rem] md:ml-[2rem] md:bottom-[2rem] sm:ml-[1rem] sm:bottom-[1rem] ml-[0.7rem] bottom-[0.7rem] font-helvetica justify-center absolute left-0 w-fit text-center text-base bg-opacity-90 leading-normal">
                <div
                    class="text-black font-bold bg-white w-fit px-2 mb-1 sm:mb-2 lg:text-[2rem] md:text-[1.5rem] sm:text-[1.25rem] text-[1rem]">
                    Call into the stream at </div>
                <div
                    class="block text-black font-bold bg-white text-start px-2 w-fit lg:text-[4rem] md:text-[2.5rem] sm:text-[2.2rem] text-[1.5rem]">
                    {{ callInLink }}</div>
            </div>
            <div v-if="hostIsLive && fitScreen" id="call-in-banner"
                class="z-[57] transform translate-y-[-50%] sm:translate-y-[0] flex flex-col sm:ml-[4rem] sm:bottom-[4rem] sm:top-auto font-helvetica justify-center absolute left-0 w-[100%] text-center text-base bg-opacity-90 leading-normal h-fit"
                :class="connectionStatus == 'connected' ? 'top-[50%]' : 'top-[20%]'">
                <div
                    class="text-black font-bold bg-white px-2 sm:mb-2 lg:text-[2.7rem] md:text-[2.025rem] sm:text-[1.6875rem] text-[1rem] sm:w-fit">
                    Call into The Stream at </div>
                <div
                    class="block text-black font-bold bg-white sm:text-start text-center px-2 lg:text-[4.9rem] md:text-[3.375rem] sm:text-[2.97rem] text-[1.5rem] sm:w-fit w-full">
                    {{ callInLink }}</div>
            </div>

            <div v-if="hostIsLive" class="absolute top-2 left-2 lg:top-4 lg:left-4 font-light text-white flex flex-row">
                <img class="h-[2rem] sm:h-[3rem] lg:h-[4rem] z-[57]" src="/logo.png">
                <div class="items-center flex text-lg ml-2 sm:text-2xl lg:text-4xl lg:ml-3">LiveStation.com</div>
            </div>
            <div class="flex flex-row justify-between absolute w-full py-1 bottom-0 bg-opacity-20 backdrop-blur-md z-10 bg-slate-600 items-center lg:px-4 px-2"
                v-if="displayTaskbar">
                <div>
                    <div class="flex flex-row" v-if="hostIsLive">
                        <button @click="stopAcceptingCalls"
                            class="lg:px-4 lg:py-1 px-2 py-1 bg-red-400 hover:bg-red-300 text-white  rounded-md w-full sm:w-auto text-3xl">
                            Stop
                        </button>
                        <button @click="disconnectFromGuest" v-if="connectionStatus == 'connected'"
                            class="ml-3 lg:px-4 lg:py-1 px-2 py-1 bg-indigo-500 hover:bg-indigo-400 text-white  rounded-md w-full sm:w-auto text-3xl">
                            Next
                        </button>
                    </div>
                    <div v-else>
                        <button @click="startAcceptingCalls"
                            class="lg:px-4 lg:py-1 px-2 py-1 bg-indigo-500 hover:bg-indigo-400 text-white  rounded-md w-full sm:w-auto text-3xl">
                            Start
                        </button>
                    </div>
                </div>

                <div class="flex flex-row items-center">
                    <button v-if="!fitScreen" @click="enterFitScreen" class="flex justify-center">
                        <span class="material-symbols-outlined text-5xl">fit_screen</span>
                    </button>
                    <button v-if="fitScreen" @click="exitFitScreen" class="flex justify-center">
                        <span class="material-symbols-outlined  text-5xl">fullscreen_exit</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>


<script setup>
import { ref, onBeforeUnmount, computed, onMounted, onUnmounted, watch, reactive } from 'vue';
import { useStore } from 'vuex'
import { firebaseApp } from '../firebaseConfig.js'; // Update the path to your firebase.js file
import { getFirestore, onSnapshot, collection, getDocs, orderBy, query, updateDoc, addDoc, writeBatch, where, getDoc, doc } from 'firebase/firestore';
const db = getFirestore(firebaseApp);
const store = useStore()
const hostVideoPlayer = ref(null)
const guestVideoPlayer = ref(null)
const mouseActiveOverVideo = ref(false)
const connectionTimeoutId = ref(null)
const hostIsLive = ref(false);
const currentConnection = ref(null)
const timeLeftInCall = ref(0)
const timeLeftIntervalId = ref(null)
const fitScreen = ref(false)
const displayCopyButton = ref(true)
const videoArea = ref(null)
const connectionRequests = reactive({ "data": [] })
const screenSize = reactive({
    width: 0,
    height: 0,
});
const connectionStatus = ref('waiting-for-calls') //'connection-in-progress' | 'connected' | 'waiting-for-calls' | 'disconnecting'
// Global State
const peerConnection = ref(null);
const userMedia = computed(() => {
    return store.state.userMediaState.userMedia
})
const username = computed(() => {
    return store.state.auth.username
})
const callInLink = computed(() => {
    return `${window.location.host}/@${username.value}`
})
//calculated by screen size 
//screen size width and heigh are calculated on mount and recalculated on screen resize
const pageOrientation = computed(() => {
    if (screenSize.width > screenSize.height) {
        return 'landscape'
    } else {
        return 'portrait'
    }
})
const hostVideoClassList = computed(() => {
    const classList = []
    if (fitScreen.value) {
        classList.push('fitscreen')
    } else {
        classList.push('not-fitscreen')
    }
    if (pageOrientation.value === 'landscape') {
        classList.push('landscape')
    } else {
        classList.push('portrait')
    }
    if (connectionStatus.value === 'connected') {
        classList.push('connection-active')
    } else {
        classList.push('not-connected')
    }
    classList.push('host-video')
    return classList
})
const guestVideoClassList = computed(() => {
    const classList = []
    if (fitScreen.value) {
        classList.push('fitscreen')
    } else {
        classList.push('not-fitscreen')
    }
    if (pageOrientation.value === 'landscape') {
        classList.push('landscape')
    } else {
        classList.push('portrait')
    }
    if (connectionStatus.value === 'connected') {
        classList.push('connection-active')
    } else {
        classList.push('not-connected')
    }
    classList.push('guest-video')
    return classList
})
const iceCandidateUnsubscribeFunction = ref(null)
//when the browser changes pages it needs to requestUserMedia again to access it
onMounted(() => {
    if (store.state.userMediaState.userMedia) {
        store.dispatch('userMediaState/requestUserMedia')
    }
})
watch(userMedia, (userMedia) => {
    if (userMedia) {
        hostVideoPlayer.value.srcObject = userMedia
        hostVideoPlayer.value.play()
    }
})

const updateScreenSizeProperty = () => {
    screenSize.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    screenSize.height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
};
async function startAcceptingCalls() {
    hostIsLive.value = true
}
const fetchConnectionRequests = async (hostId) => {
    const querySnapshot = await getDocs(
        query(collection(db, "connectionRequests"), orderBy("timestamp", "asc"), where('hostId', '==', hostId))
    );

    const requests = [];
    querySnapshot.forEach((docSnap) => {
        requests.push(docSnap)
    });

    return requests; // Return the sorted and UIDs added requests
};
let unsubscribeFromConnectionRequests;
const updateConnectionRequests = async (userSignedIn) => {
    console.log('break point below')
    let unsubscribe;
    if (userSignedIn) {
        // Initial fetch of connectionRequests and assign the value to connectionRequests
        const initialData = await fetchConnectionRequests(store.state.auth.user.uid);
        connectionRequests.data = initialData;
        // Subscribe to changes using onSnapshot
        unsubscribeFromConnectionRequests = onSnapshot(
            query(collection(db, "connectionRequests"), orderBy("timestamp", "asc"), where('hostId', '==', store.state.auth.user.uid)),
            async () => {
                // Update connectionRequests whenever connectionRequests change
                const result = await fetchConnectionRequests(store.state.auth.user.uid);
                connectionRequests.data = result;
            }
        );
    } else {
        connectionRequests.data = []
        if (unsubscribe) {
            unsubscribe()
        }
    }
}
onUnmounted(() => {
    unsubscribeFromConnectionRequests && unsubscribeFromConnectionRequests()
})
//update connection requests when the auth state chanegs
updateConnectionRequests(store?.state?.auth?.isSignedIn)
watch(() => store?.state?.auth?.isSignedIn, updateConnectionRequests)

const disconnectFromGuest = async () => {
    clearInterval(timeLeftIntervalId.value)
    console.log('started disconnecting from guest')
    connectionStatus.value = 'disconnecting'
    if (peerConnection.value) {
        peerConnection.value.onicecandidate = null
        peerConnection.value.oniceconnectionstatechange = null
        peerConnection.value.ontrack = null
        peerConnection.value && peerConnection.value.close()
        peerConnection.value = null
    }
    console.log('clear timeout')
    connectionTimeoutId.value && clearTimeout(connectionTimeoutId.value)
    //unsubscribe from listeners
    iceCandidateUnsubscribeFunction.value && iceCandidateUnsubscribeFunction.value()
    //turn everything in here into a batch
    // Create queries for 'hostIceCandidates' and 'guestIceCandidates' subcollections
    const hostIceCandidatesQuery = query(collection(db, 'connectionRequests', currentConnection.value.id, 'hostIceCandidates'));
    const guestIceCandidatesQuery = query(collection(db, 'connectionRequests', currentConnection.value.id, 'guestIceCandidates'));

    // Fetch documents using the queries
    const [hostIceCandidatesQuerySnapshot, guestIceCandidatesQuerySnapshot] = await Promise.all([
        getDocs(hostIceCandidatesQuery),
        getDocs(guestIceCandidatesQuery)
    ]);
    const batch = writeBatch(db);

    // Delete documents in 'hostIceCandidates' subcollection
    hostIceCandidatesQuerySnapshot.forEach((doc) => {
        batch.delete(doc.ref)
    });

    // Delete documents in 'guestIceCandidates' subcollection
    guestIceCandidatesQuerySnapshot.forEach((doc) => {
        batch.delete(doc.ref)
    });

    // Delete the main connectionRequests document
    batch.delete(doc(db, 'connectionRequests', currentConnection.value.id));
    connectionRequests.data = connectionRequests.data.filter(connectionRequest => connectionRequest.id !== currentConnection.value.id);
    await batch.commit()
    currentConnection.value = null
    connectionStatus.value = 'waiting-for-calls'
    console.log('finished disconnecting from guest')
}
function stopAcceptingCalls() {
    hostIsLive.value = false
    if (connectionStatus.value == 'connected') {
        disconnectFromGuest()
    }
}
function enterFitScreen() {
    fitScreen.value = true
}
function exitFitScreen() {
    fitScreen.value = false
}
let mouseActiveTimeout = null
const mouseActiveTimer = () => {
    mouseActiveOverVideo.value = true;
    clearTimeout(mouseActiveTimeout);
    mouseActiveTimeout = setTimeout(() => {
        mouseActiveOverVideo.value = false;
    }, 2000);
};
// Cleanup when component is unmounted
onBeforeUnmount(() => {
    if (hostVideoPlayer.value.srcObject) {
        hostVideoPlayer.value.srcObject.getTracks().forEach(track => {
            track.stop();
        });
    }
});
onMounted(() => {
    updateScreenSizeProperty()
    window.addEventListener('resize', updateScreenSizeProperty);
    window.addEventListener('orientationchange', updateScreenSizeProperty)
});
// Remove event listener on component unmount
onBeforeUnmount(() => {
    window.removeEventListener('resize', updateScreenSizeProperty);
    window.removeEventListener('orientationchange', updateScreenSizeProperty)
});
const copyCallInLink = () => {
    navigator.clipboard.writeText(callInLink.value);
    displayCopyButton.value = false
    setTimeout(() => {
        displayCopyButton.value = true
    }, 1000)
}
const readyForNewCall = computed(() => {
    return connectionStatus.value == 'waiting-for-calls' && hostIsLive.value && connectionRequests.data.length > 0;
});
const currentConnectionData = () => {
    let currentConnectionData = null
    if (currentConnection.value) {
        connectionRequests.data.forEach((connectionRequest) => {
            if (currentConnection.value.id == connectionRequest.id) {
                currentConnectionData = connectionRequest.data()
            }
        })
    }
    return currentConnectionData
}
const receivedAnswer = computed(() => {
    return connectionStatus.value == 'waiting-for-answer' &&
        hostIsLive.value &&
        connectionRequests.data.length > 0 &&
        !!currentConnectionData()?.guestAnswer;
});
const displayTaskbar = computed(() => {//keep taskbar open when the host isnt live. When the host is live only open it on mouse active
    if (!hostIsLive.value) {
        return true;
    } else if (mouseActiveOverVideo.value) {
        return true;
    } else {
        return false
    }
})
const currentCallEnded = computed(() => {
    return connectionStatus.value === 'connected' && currentConnectionData()?.ended === true
})
const guestCameraEnabled = computed(() => {
    return connectionStatus.value === 'connected' && currentConnectionData()?.guestCameraEnabled === true
})
const findOldestDocument = (documents) => {
    let oldestTimestamp = null;
    let oldestDoc = null; // Renamed the inner variable
    for (const documentSnap of documents) {
        const document = documentSnap.data()
        const timestamp = document.timestamp;
        const doc = documentSnap
        if (oldestTimestamp === null || timestamp < oldestTimestamp) {
            oldestTimestamp = timestamp;
            oldestDoc = doc; // Update the renamed variable
        }
    }

    // Return the ID of the oldest document
    return oldestDoc; // Return the renamed variable
};
watch(currentCallEnded, (newValue) => {
    console.log('guest ended call')
    if (newValue === true) {
        disconnectFromGuest()
    }
})
watch(receivedAnswer, async (newValue) => {
    console.log('got answer')
    if (newValue == true) {
        console.log('got answer')
        connectionStatus.value = 'waiting-for-ice-candidates';
        const updatedConnection = await getDoc(currentConnection.value.ref)
        const guestAnswer = JSON.parse(updatedConnection.data().guestAnswer)
        await peerConnection.value.setRemoteDescription(new RTCSessionDescription(guestAnswer))
        const iceCandidatesRef = collection(currentConnection.value.ref, 'guestIceCandidates');
        const unsubscribe = onSnapshot(iceCandidatesRef, (snapshot) => {
            snapshot.docChanges().forEach((change) => {
                if (change.type === "added") {
                    const iceCandidateData = change.doc.data();
                    peerConnection.value.addIceCandidate(new RTCIceCandidate(iceCandidateData));
                }
            });
        });
        iceCandidateUnsubscribeFunction.value = unsubscribe
    }
})
watch(readyForNewCall, async (newValue) => {
    console.log('ready for call')
    if (newValue == true) {
        //set next connection to current connection and set it as a ref
        //   update this  computed property on snapshot that updates connectionRequests
        //set stage to 'waiting for answer' after sending a hostOffer
        //set computed property "answerUpdated" checks for " stage == 'waiting for answer' && connectionRequests.value[currentConnectionId].guestAnswer != null
        //set a watcher that listens for answerUpdated
        //when it's fired set it to 'waiting for ice candidates'
        //when an offer is set. Create a timeout function for 3 seconds that moves on to the next call if there is no answer back
        timeLeftInCall.value = null
        connectionStatus.value = 'connection-in-progress';
        const servers = {
            iceServers: [
                {
                    urls: ['stun:stun1.l.google.com:19302', 'stun:stun2.l.google.com:19302'],
                },
            ],
            iceCandidatePoolSize: 10,
        };
        peerConnection.value = new RTCPeerConnection(servers)
        if (guestVideoPlayer.value.srcObject == null) {
            let remoteStream = new MediaStream();
            guestVideoPlayer.value.srcObject = remoteStream
        } else {
            guestVideoPlayer.value.srcObject.getTracks().forEach(track => {
                // Stop the track to ensure it's not transmitting any data
                track.stop();
                // Remove the track from the MediaStream
                guestVideoPlayer.value.srcObject.removeTrack(track);
            });
        }
        peerConnection.value.ontrack = (event) => {
            event.streams[0].getTracks().forEach((track) => {
                guestVideoPlayer.value.srcObject.addTrack(track);
            });
        };
        guestVideoPlayer.value.play()
        peerConnection.value.oniceconnectionstatechange = () => {
            const iceConnectionState = peerConnection.value.iceConnectionState;
            if (iceConnectionState === "connected") {
                connectionStatus.value = 'connected'
                timeLeftInCall.value = 120
                timeLeftIntervalId.value = setInterval(() => {
                    timeLeftInCall.value -= 1
                    if (timeLeftInCall.value == 0) {
                        disconnectFromGuest()
                    }
                }, 1000)
            } else if (iceConnectionState === "disconnected") {
                disconnectFromGuest()
            }
        };
        store.state.userMediaState.userMedia.getTracks().forEach((track) => {
            peerConnection.value.addTrack(track, store.state.userMediaState.userMedia);
        });
        //test code. Will it increase quality?
        //It did in fact increase quality ('-')7
        //UPDATE IT BROKE PRODUCTION (ￗ﹏ￗ ) 
        // const sender = peerConnection.value.getSenders()[0];
        // const parameters = sender.getParameters();
        // parameters.encodings[0].maxBitrate = 5 * 1000 * 100;
        // sender.setParameters(parameters);
        const currentConnectionLocalVar = findOldestDocument(connectionRequests.data);
        currentConnection.value = currentConnectionLocalVar

        peerConnection.value.onicecandidate = (event) => {
            console.log("adding ice")
            event.candidate && addDoc(collection(currentConnection.value.ref, 'hostIceCandidates'), event.candidate.toJSON());
        };
        const offerDescription = await peerConnection.value.createOffer();
        await peerConnection.value.setLocalDescription(offerDescription);
        await updateDoc(currentConnection.value.ref, {
            hostOffer: JSON.stringify(offerDescription)
        });
        connectionStatus.value = 'waiting-for-answer';
        connectionTimeoutId.value = setTimeout(async () => {
            if (currentConnectionLocalVar === currentConnection.value && connectionStatus.value != 'connected') {
                disconnectFromGuest()
                console.log('call timed out')
            }
        }, 7000)
    }
});
//check if a user document with a users uid has been created and if not give
//display a fullscreen multi-modal giving the user sales pitch
//asking the users questions and giving them gifs on why they should use the service
//
</script>

<style scoped>
.guest-video video,
.host-video video {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

#call-in-banner {
    transition: top 0.8s;
}

.host-video.fitscreen.landscape.connection-active {
    min-height: 100%;
    width: 50%;
    position: absolute;
    bottom: 0;
    left: 0;
}

.host-video.fitscreen.landscape.connection-active video {
    height: 100vh;
}

.guest-video.fitscreen.landscape {
    min-height: 100%;
    width: 50%;
    position: absolute;
    bottom: 0;
    right: 0;
}

.guest-video.fitscreen.landscape video {
    height: 100vh;
}

.host-video.not-fitscreen.connection-active {
    width: 50%;
    height: 100%;
    position: absolute;
    left: 0;
}

.guest-video.not-fitscreen {
    width: 50%;
    height: 100%;
    position: absolute;
    right: 0;
}

.host-video.fitscreen.portrait.connection-active {
    min-width: 100%;
    height: 50%;
    position: absolute;
    left: 0;
    top: 0;
}

.guest-video.fitscreen.portrait {
    min-width: 100%;
    height: 50%;
    position: absolute;
    right: 0;
    bottom: 0;
}

.host-video.fitscreen.not-connected {
    min-width: 100%;
    height: 100%
}

.video-area {
    width: 100%;
    height: calc(100vw * 9 / 16);
    position: relative;
}

@media (min-width: 640px) {
    .video-area {
        width: 35rem;
        height: calc(35rem * 9 / 16);
        /* 16:9 aspect ratio */
    }
}

@media (min-width: 768px) {
    .video-area {
        width: 40rem;
        height: calc(40rem * 9 / 16);
        /* 16:9 aspect ratio */
    }
}

@media (min-width: 1024px) {
    .video-area {
        width: 60rem;
        height: calc(60rem * 9 / 16);
        /* 16:9 aspect ratio */
    }
}

/* Hide the default play button on Chrome */
/* Hide the default play button on Chrome */
video::-webkit-media-controls-enclosure {
    display: none !important;
}

/* Hide other controls you may not need (optional) */
video::-webkit-media-controls {
    display: none !important;
}
</style>
