const state = {
    stage: 'pre-prompt', // 'pre-prompt' | 'accepted' | 'no-device' | 'denied' | 'camera-occupied
    userMedia: null,
    error: null
};

const mutations = {
    SET_STAGE(state, newStage) {
        state.stage = newStage;
    },
    SET_USER_MEDIA(state, mediaData) {
        state.userMedia = mediaData;
    },
    SET_ERROR(state, error) { // New mutation to set if the user is on a mobile browser
        state.error = error;
    }
};

const actions = {
    async requestUserMedia({ commit }) {
        let timeoutID;
        try {
            const getUserMedia = navigator?.mediaDevices?.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia || navigator.getUserMedia;

            if (!getUserMedia) {
                commit('SET_STAGE', 'no-device');
                return;
            }

            const constraints = {
                "video": {
                    "facingMode": "user",
                    "aspectRatio": 16 / 9
                }, "audio": {
                    "autoGainControl": true,
                    "echoCancellation": true,
                    "noiseSuppression": true,
                    "googHighpassFilter": true
                }
            };
            timeoutID = setTimeout(()=>{// the only situation getUserMedia takes longer than 3 seconds to return would be if its prompting the user
                commit('SET_STAGE', 'pre-prompt')
            }, 3000)
            const stream = await getUserMedia.call(navigator.mediaDevices, constraints); // Call directly from the navigator object
            clearTimeout(timeoutID)
            // Media was accepted, set the userMedia in the state
            commit('SET_USER_MEDIA', stream);
            commit('SET_STAGE', 'accepted');
        } catch (error) {
            clearTimeout(timeoutID)
            if (error.name === 'NotFoundError') {
                commit('SET_STAGE', 'no-device');
            } else if (error.name === 'AbortError' || error.name === 'NotReadableError') {
                commit('SET_STAGE', 'camera-occupied');
            } else {
                // Handle other errors (e.g., PermissionDeniedError)
                commit('SET_STAGE', 'denied');
            }
        }
    }, async requestUserMediaUntilAccepted({ dispatch, state }) {
        // Use setInterval to periodically call requestUserMedia
        await dispatch('requestUserMedia')
        if (state.stage != 'accepted') {
            const intervalId = setInterval(async () => {
                await dispatch('requestUserMedia');
                if (state.stage === 'accepted') {
                    clearInterval(intervalId); // Stop the interval when the desired state is reached
                }
            }, 2000); // Call every 2 second            
        }

    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};