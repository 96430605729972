<template>
    <image-and-buttons-modal-template :buttons="buttons" :message="message" :photo-url="photoUrl" />
</template>
<script setup>
import ImageAndButtonsModalTemplate from '../ImageAndButtonsModalTemplate.vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { computed, ref } from 'vue';
const router = useRouter();
const store = useStore();
const message = 'We need access to your camera to continue'
const photoUrl = computed(() => {
    if (store.state.browserState.isMobile) {
        return "/allow-camera-mobile.gif"
    } else {
        return '/allow-camera-access-chrome.gif';
    }
})
const buttons = ref([
    {
        name: "Allow",
        function: () => {
            buttons.value[0].disabled = true
            if (store.state.browserState.isMobile) {
                store.dispatch('userMediaState/requestUserMedia')
            } else {
                store.dispatch('userMediaState/requestUserMediaUntilAccepted')
            }
        },
        class: "text-white bg-blue-700 hover:bg-blue-900 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center",
        disabledButtonClasses: "text-white bg-blue-900 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center",
        disabled: false
    },
    {
        name: "Leave Page",
        function: () => {
            buttons.value[1].disabled = true
            router.push({ name: 'home' });
        },
        class: "ms-3 text-gray-500 bg-white hover:bg-gray-300 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10",
        disabledButtonClasses: "ms-3 bg-gray-300 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 text-gray-900 focus:z-10",
        disabled: false
    }
])
</script>

<style scoped></style>
