<template>
    <image-and-buttons-modal-template :buttons="buttons" :message="message" :photo-url="photoUrl" />
</template>
<script setup>
import ImageAndButtonsModalTemplate from '../ImageAndButtonsModalTemplate.vue';
import { useStore } from 'vuex';
import { computed } from 'vue';
const store = useStore();
const message = computed(() => {
    if (store.state.browserState.isMobile) {
        return "Camera Access Denied. Please refresh your page to continue."
    } else {
        return "Camera Access Denied. Change camera permissions to continue."
    }
})
const photoUrl = computed(() => {
    if (store.state.browserState.isMobile) {
        return '/refresh-mobile.gif'
    } else {
        if (store.state.browserState.browser == 'Chrome') {
            return '/undeny-camera-chrome.gif';
        } else if (store.state.browserState.browser == 'Firefox') {
            return '/undeny-camera-firefox.gif';
        } else if (store.state.browserState.browser == 'Opera') {
            return '/undeny-camera-opera.gif';
        } else if (store.state.browserState.browser == 'Safari') {
            return '/undeny-camera-safari.gif';
        } else if (store.state.browserState.browser == 'Edge') {
            return '/undeny-camera-edge.gif';
        } else {//unknown browser
            return '/undeny-camera-chrome.gif';
        }
    }
})
const buttons = [
]
</script>

<style scoped></style>
