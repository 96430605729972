import { initializeApp } from 'firebase/app';
import { onAuthStateChanged, getAuth } from 'firebase/auth';
// Your Firebase configuration object
const firebaseConfig = {
  apiKey: "AIzaSyAyYVVnQVonyqCQf69Heq6L40AjPGvaKqo",
  authDomain: "fantime-39362.firebaseapp.com",
  projectId: "fantime-39362",
  storageBucket: "fantime-39362.appspot.com",
  messagingSenderId: "834733819625",
  appId: "1:834733819625:web:6c09b62f9907b200f9d278",
  measurementId: "G-1S571XVWGY"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp)

function getCurrentUser() {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
}
export { firebaseApp, getCurrentUser };
