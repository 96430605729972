const state = {
    browser: null, // New property to store the detected browser
    isMobile: false, // New property to store if the user is on a mobile browser,
};
const mutations = {
    SET_BROWSER(state, browser) { // New mutation to set the detected browser
        state.browser = browser;
    },
    SET_IS_MOBILE(state, isMobile) { // New mutation to set if the user is on a mobile browser
        state.isMobile = isMobile;
    }
};
const actions = {
    detectAndSetBrowser({ commit }) {
        const userAgent = navigator.userAgent;

        const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

        if (isMobile) {
            // User is on a mobile browser
            commit('SET_IS_MOBILE', true);
        }

        if (/Snapchat/i.test(userAgent)) {
            // Firefox browser
            commit('SET_BROWSER', 'Snapchat-In-App-Browser');
        }else if (/Bytedance/i.test(userAgent)) {
            // Firefox browser
            commit('SET_BROWSER', 'Tiktok-In-App-Browser');
        }else if (/firefox/i.test(userAgent)) {
            // Firefox browser
            commit('SET_BROWSER', 'Firefox');
        } else if (/OPR/i.test(userAgent)) {
            // Opera browser
            commit('SET_BROWSER', 'Opera');
        } else if (/chrome/i.test(userAgent)) {
            // Chrome browser
            commit('SET_BROWSER', 'Chrome');
        } else if (/safari/i.test(userAgent)) {
            // Safari browser
            commit('SET_BROWSER', 'Safari');
        } else if (/edge/i.test(userAgent)) {
            // Microsoft Edge browser
            commit('SET_BROWSER', 'Edge');
        } else {
            // Other/Unknown browser
            commit('SET_BROWSER', 'Unknown');
        }
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};