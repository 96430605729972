<template>
    <nav-bar />
    <div class="mt-[6rem]">
        <router-view />
        <Transition name="backdrop" v-show="!!modalComponent">
            <div class="backdrop fixed top-0 right-0 left-0 z-[100] w-full h-full">
                <Transition name="modal">
                    <component :is="modalComponent" class="modal" />
                </Transition>
            </div>
        </Transition>
    </div>
</template>

<script setup>
import NavBar from './components/NavBar.vue';
import SignInModal from './components/modals/SignInModal.vue';
import SetUsernameModal from './components/modals/SetUsernameModal.vue';
import RequestUserMediaModal from './components/modals/UserMediaConnection/RequestUserMediaModal.vue'
import ConnectCameraModal from './components/modals/UserMediaConnection/ConnectCameraModal.vue'
import CloseOtherCameraApps from './components/modals/UserMediaConnection/CloseOtherCameraAppsModal.vue';
import UndenyCameraAccess from './components/modals/UserMediaConnection/UndenyCameraAccessModal.vue';
import OpenInBrowserModal from './components/modals/OpenInBrowserModal.vue';
import { useStore } from 'vuex';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
const route = useRoute();
const store = useStore();

store.dispatch('browserState/detectAndSetBrowser')

const modalComponent = computed(() => {
    // First, check if authentication is required and the user is in an in-app browser.
    // This is specific to cases where certain features might not work as expected in in-app browsers,
    // and the user is prompted to open the application in a standard browser for full functionality.
    if (route.meta.requiresAuth && /In-App-Browser/i.test(store.state.browserState.browser)) {
        return OpenInBrowserModal;
    } 
    // Check if the sign-in state is explicitly known (i.e., not null).
    // This is necessary to ensure we only proceed with additional checks if we have a clear sign-in status.
    else if (store.state.auth.isSignedIn != null) {
        // If the user is not signed in and authentication is required, prompt them to sign in.
        if (!store.state.auth.isSignedIn && route.meta.requiresAuth) {
            return SignInModal;
        } 
        // If the user is signed in but does not have a username set,
        // and the current route requires a username, prompt them to set a username.
        else if (!store.state.auth.username && route.meta.requiresUserName) {
            return SetUsernameModal;
        } 
        // If the route requires user media (like camera or microphone access),
        // we determine which modal to show based on the user media state.
        else if (route.meta.requiresUserMedia) {
            // Pre-prompt state indicates the user hasn't been asked for media permissions yet.
            if (store.state.userMediaState.stage == 'pre-prompt') {
                return RequestUserMediaModal;
            } 
            // No-device indicates the user does not have the necessary media devices.
            else if (store.state.userMediaState.stage == 'no-device') {
                return ConnectCameraModal;
            } 
            // Denied indicates the user has denied access to their media devices.
            else if (store.state.userMediaState.stage == 'denied') {
                return UndenyCameraAccess;
            } 
            // Camera-occupied indicates another application is using the camera,
            // preventing the app from accessing it.
            else if (store.state.userMediaState.stage == 'camera-occupied') {
                return CloseOtherCameraApps;
            }
        }
    }
    // If none of the above conditions are met, no modal is required.
    return null;
});
</script>

<style scoped>
.backdrop {
    background-color: rgba(51, 65, 85, 0.8);
}

.backdrop-enter-active,
.backdrop-leave-active {
    transition: background-color 0.3s ease-in;
}

/* Entering state */
.backdrop-enter-from,
.backdrop-leave-to {
    background-color: rgba(51, 65, 85, 0);
}

.modal {
    transform: translate(-50%, -50%);
}

.modal-enter-active,
.modal-leave-active {
    transition: opacity 0.3s linear;
}

/* Entering state */
.modal-enter-from {
    opacity: 0;
}

.modal-leave-to {
    opacity: 1;
}
</style>
