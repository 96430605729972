<template>
    <image-and-buttons-modal-template :buttons="buttons" :message="message" :photo-url="photoUrl" />
</template>
<script setup>
import ImageAndButtonsModalTemplate from './ImageAndButtonsModalTemplate.vue';
import { computed } from 'vue';
const message = computed(() => {
    return "Open in browser to continue"
})
const photoUrl = computed(() => {
    return '/open-in-browser.gif';
})
const buttons = [
]
</script>
