import { createStore } from 'vuex'
import authModule from './authModule.js'
import userMediaModule from './userMediaModule.js'
import browserModule from './browserModule.js';
const store = createStore({
    modules: {
        auth: authModule,
        userMediaState: userMediaModule,
        browserState: browserModule
    }
});

export default store;