<template>
  <div class="fixed top-1/2 left-1/2 p-4 w-full max-w-2xl max-h-full modal">
    <!-- Modal content -->
    <div class="relative bg-white rounded-lg shadow signInModal">
      <!-- Modal header -->
      <div class="flex items-center justify-center p-4 md:p-5 border-b rounded-t">
        <h3 class="text-2xl font-semibold text-gray-900">
          Login to continue
        </h3>
      </div>
      <!-- Modal body -->
      <div class="p-4 md:p-5 space-y-4">
        <button @click="signIn"
          class="sign-in-button bg-gray-200 text-gray-500 font-semibold py-4 px-8 justify-center w-full rounded-lg flex items-center relative text-[1.6rem]">
          <img src="/google.svg" alt="Google Icon" class="w-6 h-6 mr-4" />
          Login
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { defineProps } from 'vue';

defineProps({
  message: String, // Define the 'message' prop
});

const store = useStore();
const signIn = async () => {
  await store.dispatch('auth/signIn');
  await store.dispatch('auth/updateAuthState')
}
// this is a modal that will prompt the user to sign in to continue
// it will use tailwind, be responsive and transition into the screen
</script>
<style scoped></style>